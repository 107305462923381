import { Box, Paper, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors"
// @ts-ignore
import DOMPurify from "dompurify"; // Assicurati di importare DOMPurify

export default function RowDomandaRiepilogoQuestionario(props: { quiz: any; index: number }) {
    function changeColorAnswerText(domanda: any, choice: any) {
        if (choice.value === domanda.givenAnswer) {
            // Se è la risposta selezionata
            if (!domanda.correctAnswer.includes(choice.value)) {
                return red[600];
            }
        }

        if (domanda.correctAnswer.includes(choice.value)) {
            return green[600];
        }
    }

    return (
        <Box px={{ sx: 0, md: 3 }} sx={{ my: 2 }}>
            <Paper elevation={4} sx={{ p: 5, mx: 2 }}>
                <Typography variant="subtitle2">Risposta n. {props.index + 1}</Typography>
                <Box sx={{ display: "flex" }}>
                    <Typography display={{ xs: "none", md: "flex" }} variant="h5">
                        <div
                            style={{ overflowY: "auto", maxHeight: "20vh" }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.quiz.title) }}
                        ></div>
                    </Typography>
                    <Typography display={{ xs: "flex", md: "none" }} sx={{ mb: 2, mt: 1 }} variant="h6">
                        <div
                            style={{ overflowY: "auto", maxHeight: "20vh" }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.quiz.title) }}
                        ></div>
                    </Typography>
                </Box>
                {props.quiz.choices.map((choice: any, i: number) => (
                    <Box
                        key={i}
                        sx={{ display: "flex", justifyContent: "start", alignItems: "center", mt: { xs: 2, md: 2 } }}
                    >
                        <Typography variant="body1" sx={{ mr: 1 }}>
                            {(i + 10).toString(36).toUpperCase() + ")"}
                        </Typography>
                        <div
                            style={{ overflowY: "auto", maxHeight: "20vh" }}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choice.text) }}
                        />
                        <Typography variant="subtitle2" sx={{ color: changeColorAnswerText(props.quiz, choice) }}>
                            {props.quiz?.givenAnswer.indexOf(choice.value) !== -1 &&
                                props.quiz?.correctAnswer.indexOf(choice.value) === -1 && (
                                    <span style={{ marginLeft: "10px" }}>(Risposta Fornita - Errata)</span>
                                )}
                            {props.quiz?.givenAnswer.indexOf(choice.value) === -1 &&
                                props.quiz?.correctAnswer.indexOf(choice.value) !== -1 && (
                                    <span style={{ marginLeft: "10px" }}>(Corretta)</span>
                                )}
                            {props.quiz?.givenAnswer.indexOf(choice.value) !== -1 &&
                                props.quiz?.correctAnswer.indexOf(choice.value) !== -1 && (
                                    <span style={{ marginLeft: "10px" }}>(Risposta Fornita - Corretta)</span>
                                )}
                        </Typography>
                    </Box>
                ))}

                {props.quiz.givenAnswer.length === 0 && (
                    <Typography variant="subtitle1" sx={{ mt: 2, color: "red" }}>
                        Risposta Omessa
                    </Typography>
                )}
            </Paper>
        </Box>
    );
}
