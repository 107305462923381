import * as React from 'react';
import { useState, useEffect, Fragment } from "react";
import { Grid, Box, Button, Typography, Paper, Slide, Skeleton, Dialog, DialogTitle, IconButton } from '@mui/material';
import { FormatListBulleted as FormatListBulletedIcon, ArrowForward as ArrowForwardIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FaqVolume from './faqVolume';

const Transition = React.forwardRef(function Transition(props: React.ComponentProps<typeof Slide>, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface VolumeProps {
    volume: {
        id:number,
        isbn: string;
        titolo: string;
        copertina: string;
        questionari: { id: number | null; nome: string; }[];
    };
    volumiLoaded: boolean;
    isbnVolumeRichiesto?: string | null;
    showVolumeRichiesto?: boolean;
    volumeRichiesto?: string;
    utente: User;
}

type User = {
    id?: string;
    ruolo?: string;
    email?: string;
    nome?: string;
};

export default function DialogVolume(props: VolumeProps) {
    const [listQuestionari] = useState(props.volume.questionari);
    const [isLoaded, setIsLoaded] = useState<boolean>(false), [imgVolumeLoaded, setImgVolumeLoaded] = useState<boolean>(true);
    const [elencoQuestionariOpen, setElencoQuestionariOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setElencoQuestionariOpen(props.volume.isbn === props.isbnVolumeRichiesto);
    }, []);

    useEffect(() => {
        if (props.isbnVolumeRichiesto)
            setElencoQuestionariOpen(props.isbnVolumeRichiesto === props.volume.isbn);
    }, [props.isbnVolumeRichiesto]);

    useEffect(() => {
        if (props.volumiLoaded !== isLoaded)
            setIsLoaded(props.volumiLoaded);
    }, [props.volumiLoaded]);

    useEffect(() => {
        if (props.showVolumeRichiesto && props.volumeRichiesto === props.volume.isbn)
            setElencoQuestionariOpen(props.showVolumeRichiesto);
    }, [props.showVolumeRichiesto, props.volumeRichiesto]);

    function openElencoQuestionari(openState: boolean): void {
        if (!props.isbnVolumeRichiesto) {
            setElencoQuestionariOpen(openState);
        }
    }

    function goToQuestionario(idQuestionario: number): void {
        navigate("/sim", {
            state: {
                idQuestionario,
                idUtente: props.utente.id,
                isbn: props.volume.isbn,
            },
        });
    }


    return (
        <Fragment>
            {props.isbnVolumeRichiesto == null && (
                <Grid item xs={12} md={4} lg={3} xl={3}>
                    {isLoaded ? (
                        <Box sx={{ p: 5 }} className="volumeCard">
                            {imgVolumeLoaded ? (
                                <img draggable="false" alt="Copertina del volume" style={{ borderRadius: '5px', maxWidth: '75%' }} src={props.volume.copertina} />
                            ) : (
                                <Skeleton variant="rectangular" sx={{ height: { xs: '300px', md: '350px', lg: '300px', xl: '250px' }, borderRadius: '5px', width: '100%', backgroundColor: '#ffffff61' }} />
                            )}
                            <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>{props.volume.titolo}</Typography>
                            <Button onClick={() => openElencoQuestionari(true)} className="whiteButton" sx={{ mt: 2, display: 'flex', alignItems: 'center' }} fullWidth variant="contained" endIcon={<FormatListBulletedIcon />}>Vai ai simulatori</Button>
                            <Box className='backgroundVolumeCard' sx={{ backgroundImage: `url(${props.volume.copertina})` }}></Box>
                        </Box>
                    ) : (
                        <Skeleton variant="rectangular" sx={{ height: { xs: '350px', md: '400px', lg: '500px', xl: '400px' } }} />
                    )}
                </Grid>
            )}

            <Dialog fullWidth maxWidth="xl" open={elencoQuestionariOpen} TransitionComponent={Transition} onClose={() => openElencoQuestionari(false)}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
               <FaqVolume idVolume={props.volume.id}/>
                    <IconButton onClick={() => openElencoQuestionari(false)}><CloseIcon /></IconButton>
                </DialogTitle>
                <Box sx={{ p: 5, mb: 3 }} className="volumeSelezionatoCard">
                    <Grid container spacing={2} style={{ zIndex: '1' }}>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
                                {imgVolumeLoaded ? (
                                    <img draggable="false" alt="Copertina del volume" className="copertina-volume-dialog" src={props.volume?.copertina} />
                                ) : (
                                    <Skeleton variant="rectangular" sx={{ height: { xs: '200px', md: '300px', lg: '400px', xl: '300px' }, borderRadius: '5px', maxWidth: '45%' }} />
                                )}
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
                                <img draggable="false" alt="Copertina del volume" style={{ borderRadius: '5px', maxWidth: '100%' }} src={props.volume.copertina} />
                            </Box>
                        </Grid>
                        <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Typography variant="h3" sx={{ backgroundColor: '#000000ab', px: 2 }}>
                                    {props.volume?.titolo}
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                <Typography variant="body2" sx={{ backgroundColor: '#000000ab', px: 2 }}>
                                    {props.volume.titolo}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className='backgroundVolumeSelezionatoCard' sx={{ backgroundImage: `url(${props.volume.copertina})` }}></Box>
                </Box>
                <Box sx={{ px: { xs: 2, md: 5 }, pb: 5, overflowY: 'auto' }}>
                    <Grid container spacing={3} alignItems="stretch">
                       
                        {listQuestionari.length > 0 ? (
                            listQuestionari.map((questionario, index) => (
                                <Fragment key={index}>
                                    {questionario.id != null && (
                                        <Grid item md={6} xs={12}>
                                            <Paper sx={{ height: { xs: 'fit-content', md: '100%' }, p: 2, display: 'flex', alignItems: 'center' }} elevation={4}>
                                                <Grid container spacing={3} sx={{ height: '100%' }}>
                                                    <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                        <ArrowForwardIcon sx={{ mr: 2 }} color='success' />
                                                        <Typography variant="subtitle1">{questionario.nome}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                        <Button fullWidth variant="contained" color="success" sx={{ color: 'white' }} onClick={() => goToQuestionario(questionario.id!)}>Vai al simulatore</Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Fragment>
                            ))
                        ) : (
                            <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}>
                                <Typography variant="h5">Nessun simulatore associato al volume.</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Dialog>
        </Fragment>
    );
}
