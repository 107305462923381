import { Box, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import { ChatBubbleOutline, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import Segnalazione from "./segnalazione";
import { formatUtcInFormatoLeggibile } from "../../utils/utilityFunction";
import { PostObj } from "src/types/questionario.types";

interface ThreadProps {
    objThread: PostObj;
    utenteWantsNotifiche: boolean;
    loadingLike: boolean;
    areCommentiLoading: boolean;
    showCommentBtn: boolean;
    onLike: () => void;
    onToggleCommentSection: () => void;
    onChangeNotifiche: () => void;
    segnalazionePostInviata: () => void;
    idQuestionario: number;
}

export default function Thread(props: ThreadProps) {
    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                    {props.objThread?.nomeUtente}
                </Typography>
                <Typography variant="body2" sx={{ fontStyle: "italic", opacity: 0.5 }}>
                    {formatUtcInFormatoLeggibile(props.objThread?.data, true)}
                </Typography>
            </Box>
            {props.objThread.createdByUser && !props.showCommentBtn && (
                <FormControlLabel
                    sx={{ mb: 1 }}
                    control={<Checkbox onChange={props.onChangeNotifiche} checked={props.utenteWantsNotifiche} />}
                    label="Ricevi email di notifica quando un utente interagisce con il tuo post"
                />
            )}
            <Typography variant="h6">{props.objThread?.titolo}</Typography>
            <Typography variant="body1">{props.objThread?.testo}</Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            disabled={props.loadingLike}
                            onClick={props.onLike}
                            color={props.objThread?.utenteHasLike ? "primary" : "inherit"}
                        >
                            {props.objThread?.utenteHasLike ? <ThumbUp /> : <ThumbUpOutlined />}
                        </IconButton>
                        <Typography variant="subtitle2">{props.objThread?.numeroLike}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                        {props.showCommentBtn ? (
                            <IconButton onClick={props.onToggleCommentSection} disabled={props.areCommentiLoading}>
                                <ChatBubbleOutline />
                            </IconButton>
                        ) : (
                            <Box sx={{ width: "30px", height: "30px" }}>
                                <ChatBubbleOutline />
                            </Box>
                        )}
                        <Typography variant="subtitle2">{props.objThread?.numeroCommenti}</Typography>
                    </Box>
                </Box>
                {!props.objThread.createdByUser && (
                    !props.objThread.utenteHasSegnalazione ? (
                        <Typography variant="body1" color="error">Post segnalato</Typography>
                    ) : (
                        <Segnalazione
                            segnalazioneInviata={props.segnalazionePostInviata}
                            utenteHasSegnalazione={props.objThread.utenteHasSegnalazione}
                            idQuestionario={props.idQuestionario}
                            idThread={props.objThread.id}
                        />
                    )
                )}
            </Box>
        </Box>
    );
}
