import { ThumbUp, ThumbUpOutlined, Warning } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { formatUtcInFormatoLeggibile } from "../../utils/utilityFunction";
import { useEffect, useState } from "react";
import { CommentoObj } from "src/types/questionario.types"
import { cp } from "fs";
import { SocialAPI } from "src/api/socialApi";
import Segnalazione from "./segnalazione";

export default function CommentoPost(props: Props) {

    const [commentoObj, setCommentoObj] = useState<CommentoObj>(props.commentoObj)
    const [loadingLike, setLoadingLike] = useState(false);

    useEffect(() => {
        if (props.commentoObj)
            setCommentoObj(props.commentoObj)
    }, [props.commentoObj])


    function likeCommento() {
        if (!commentoObj) return;

        setLoadingLike(true);
        const updatedCommento = {
            ...commentoObj,
            utenteHasLike: !commentoObj.utenteHasLike,
            numeroLike: !commentoObj.utenteHasLike ? commentoObj.numeroLike + 1 : commentoObj.numeroLike - 1,
        };

        const dataObj = {
            idQuestionario: props.idQuestionario,
            idThread: props.idThread,
            idCommento: commentoObj.id
        }

        updatedCommento.utenteHasLike ? SocialAPI.saveLike(dataObj) : SocialAPI.deleteLike(dataObj);

        setCommentoObj(updatedCommento);

        setTimeout(() => {
            setLoadingLike(false);
            if (props.commentChanged) {
                props.commentChanged(updatedCommento);
            }
        }, 100);
    }

    function segnalazioneCommentoInviata() {
        const updatedCommento = {
            ...commentoObj,
            utenteHasSegnalazione: true
        };
        setCommentoObj(updatedCommento);
        props.commentChanged(updatedCommento);
    }

    return (
        <Box >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{commentoObj.nomeUtente}</Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic', opacity: 0.5 }} >{formatUtcInFormatoLeggibile(commentoObj.data)}</Typography>
            </Box>
            <Typography variant="body1">{commentoObj.testo}</Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton disabled={loadingLike} onClick={likeCommento} color={commentoObj.utenteHasLike ? "primary" : "inherit"}>
                        {commentoObj.utenteHasLike ? <ThumbUp /> : <ThumbUpOutlined />}
                    </IconButton>
                    <Typography variant="subtitle2">{commentoObj.numeroLike}</Typography>
                </Box>
                {!commentoObj.createdByUser && (
                    props.commentoObj.utenteHasSegnalazione ? (
                        <Typography variant="body1" color="error">Commento segnalato</Typography>
                    ) : (
                        <Segnalazione segnalazioneInviata={segnalazioneCommentoInviata} utenteHasSegnalazione={commentoObj.utenteHasSegnalazione} idCommento={commentoObj.id} idThread={props.idThread} idQuestionario={props.idQuestionario} />
                    )
                )}
            </Box>
        </Box>
    )
}

interface Props {
    commentChanged: (objCommento: CommentoObj) => void;
    commentoObj: CommentoObj,
    idQuestionario: number,
    idThread: number
}