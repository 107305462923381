export function formatUtcInFormatoLeggibile(utcDate: string | undefined, showOra?: boolean): string {
    if (utcDate) {
        const months = [
            "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno",
            "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"
        ];

        const date = new Date(utcDate);

        if (isNaN(date.getTime())) {
            throw new Error("Data non valida");
        }

        const day = date.getUTCDate(); // Giorno
        const month = months[date.getUTCMonth()]; // Nome mese
        const year = date.getUTCFullYear(); // Anno

        let formattedDate = `${day} ${month} ${year}`;

        if (showOra) {
            const hours = date.getUTCHours().toString().padStart(2, "0"); // Ore
            const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Minuti
            formattedDate += ` - ${hours}:${minutes}`;
        }

        return formattedDate;
    } else return "";
}
