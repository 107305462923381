import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Grid, Typography, Dialog, Alert, Button, Container, DialogContent, DialogTitle, TextField, CircularProgress, } from '@mui/material';
import { CookieOutlined, LinkOutlined, Person2Outlined } from '@mui/icons-material';

import Footer from '../shared/footer';
import DialogVolume from './dialogVolume';


export default function DashboardTs(props: DashboardProps) {
    const location = useLocation();

    const [isLogginLoaded, setIsLogginLoaded] = useState<boolean>(false);
    const [volumeRichiesto, setVolumeRichiesto] = useState<string | undefined>(undefined);
    const [utente, setUtente] = useState<User>({});
    const [showVolumeRichiesto, setShowVolumeRichiesto] = useState<boolean>(false);
    const [listVolumi, setListVolumi] = useState<any[]>([]);
    const [listVolumiPlaceholder, setListVolumiPlaceholder] = useState<any[]>([]);
    const [volumiLoaded, setVolumiLoaded] = useState<boolean>(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
    const [errorMsgDialog, setErrorMsgDialog] = useState<string>("");
    const [dialogTestQuestionarioGratuitoOpen, setDialogTestQuestionarioGratuitoOpen] = useState<boolean>(false);
    const [linkQuestionarioGratuito, setLinkQuestionarioGratuito] = useState<string>("");
    const [hasLinkQuestionarioGratuitoError, setHasLinkQuestionarioGratuitoError] = useState<boolean>(false);
    const [isCheckingPermalink, setIsCheckingPermalink] = useState<boolean>(false);

    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(window.location.search);

        if (process.env.REACT_APP_IS_DEBUG === "false") {
            handleProductionLoginFlow(params, url);
        } else {
            handleDebugLoginFlow(params);
        }
    }, []);

    function handleProductionLoginFlow(params: URLSearchParams, url: string) {
        if (params.has("token")) {
            localStorage.setItem('token', params.get("token")!);
            login(params.get("token")!);
            window.history.pushState("", "", url.split('?')[0]);
        } else {
            handleLocalStorageLogin();
        }
    }

    function handleDebugLoginFlow(params: URLSearchParams) {
        if (params.has("token")) {
            localStorage.setItem('token', params.get("token")!);
            login(params.get("token")!);
        } else if (localStorage.getItem("isLoggedFO")) {
            handleLocalStorageLogin();
        } else {
            login("A7x702zwnOQtBkiDrWkACP3lcWstRrlp3S5KK7sul9wx89Lk1X2tyg9VEnh9ScRNHelrgPcrBnbMOZpca5GDIKk=");
        }
    }

    function handleLocalStorageLogin() {
        if (localStorage.getItem("isLoggedFO") === "true") {
            if (localStorage.getItem("volumeRichiesto") && location.state?.volumeSelezionato) {
                const isbnRichiesto = localStorage.getItem("volumeRichiesto")!;
                setVolumeRichiesto(isbnRichiesto);
                getQuestionariVolumeRichiesto(isbnRichiesto);
                setShowVolumeRichiesto(true);
                setVolumiLoaded(true);
                setIsLogginLoaded(true);
            } else {
                getPurchasedList();
            }
        } else {
            redirectToSessionExpired();
        }
    }

    function redirectToSessionExpired() {
        window.location.href = `${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_PREFIX}/sessione-scaduta`;
    }

    function abilitaAlertCookieHandler() {
        if (props.abilitaAlertCookie)
            props.abilitaAlertCookie();
    }

    function openDialogQuestionarioGratuito() {
        setLinkQuestionarioGratuito("");
        setDialogTestQuestionarioGratuitoOpen((prev) => !prev);
    }

    function changeLinkQuestionarioGratuito(e: React.ChangeEvent<HTMLInputElement>) {
        setLinkQuestionarioGratuito(e.target.value);
    }

    function cleanLocalStorageAndReloadDebug(showCookies: boolean) {
        localStorage.clear();
        if (!showCookies) localStorage.setItem('cookieAccepted', "true");
        window.location.reload();
    }

    async function permalinkQuestionarioGratuitoCheck() {
        if (linkQuestionarioGratuito) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({ 'permalink': linkQuestionarioGratuito })
            };

            setIsCheckingPermalink(true);

            try {
                const res = await fetch(`${process.env.REACT_APP_FETCH_URL}questionario/permalink/check`, requestOptions);
                if (res.status === 200) {
                    permalinkResolver(linkQuestionarioGratuito);
                } else {
                    setHasLinkQuestionarioGratuitoError(true);
                    setIsCheckingPermalink(false);
                }
            } catch (error) {
                setIsCheckingPermalink(false);
            }
        }
    }

    async function permalinkResolver(permalink: string) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({ 'permalink': permalink })
        };

        try {
            const res = await fetch(`${process.env.REACT_APP_FETCH_URL}questionario/permalink/resolve`, requestOptions);
            if (res.status === 200) {
                res.json().then(() => {
                    setIsCheckingPermalink(false);
                    setHasLinkQuestionarioGratuitoError(false);
                    window.open(`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_PREFIX}/sim/${permalink}`, "_blank");
                });
            } else {
                setIsCheckingPermalink(false);
                setHasLinkQuestionarioGratuitoError(true);
            }
        } catch (error) {
            setIsCheckingPermalink(false);
        }
    }

    async function login(token: string) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        };

        try {
            const res = await fetch(`${process.env.REACT_APP_FETCH_URL}auth/loginWP`, requestOptions);
            if (res.status === 200) {
                const result = await res.json();
                const utenteTmp: User = {
                    id: result.id,
                    ruolo: result.ruolo,
                    email: result.email,
                    nome: result.nome
                };

                setUtente(utenteTmp);
                localStorage.setItem('id', result.id);
                localStorage.setItem('email', result.email);
                localStorage.setItem('isLoggedFO', "true");
                localStorage.setItem('utente', JSON.stringify(utenteTmp));
                localStorage.setItem('volumeRichiesto', result.volumeRichiesto);

                setIsLogginLoaded(true);
                handlePostLogin(result);
            } else {
                handleLoginError();
            }
        } catch (error) {
            console.error('Errore durante il login: ', error);
        }
    }

    function handlePostLogin(result: any) {
        if (!location.state) {
            setVolumeRichiesto(result.volumeRichiesto);
            if (result.volumeRichiesto) {
                getQuestionariVolumeRichiesto(result.volumeRichiesto);
                setShowVolumeRichiesto(true);
            } else {
                getPurchasedList();
                setListVolumiPlaceholder(result.volumi);
                setShowVolumeRichiesto(false);
            }
        } else {
            if (location.state.isbn) {
                setVolumeRichiesto(location.state.isbn);
            }
            setShowVolumeRichiesto(false);
            getPurchasedList();
            setListVolumiPlaceholder(result.volumi);
        }
    }

    function handleLoginError() {
        localStorage.setItem('isLoggedFO', "false");
        localStorage.removeItem('token');
        localStorage.removeItem('utente');
        redirectToSessionExpired();
    }

    async function getQuestionariVolumeRichiesto(isbn: string) {
        try {
            const res = await fetch(`${process.env.REACT_APP_FETCH_URL}questionario/list/byISBN/${isbn}`);
            if (res.status === 200) {
                const result = await res.json();
                setListVolumi([{
                    isbn,
                    id: result.data.volume.idVolume,
                    codice: result.data.volume.titolo,
                    questionari: result.data.questionari,
                    copertina: result.data.volume.copertina,
                    titolo: result.data.volume.titolo,
                }]);
                setVolumiLoaded(true);
            } else if (res.status === 401) {
                handleAuthError(res);
            } else if (res.status === 404) {
                getPurchasedList();
            }
        } catch (error) { }
    }

    async function getPurchasedList() {
        try {
            const res = await fetch(`${process.env.REACT_APP_FETCH_URL}questionario/list/purchased`);
            if (res.status === 200) {
                const result = await res.json();
                if (result.success) {

                    const listVolumiTmp = result.data.map((item: any) => ({
                        isbn: item.isbn, 
                        id: item.idVolume,
                        codice: item.titolo,
                        questionari: item.questionari,
                        copertina: item.copertina,
                        titolo: item.titolo
                    }));

                    setListVolumi(listVolumiTmp);
                    setVolumiLoaded(true);
                    setIsLogginLoaded(true);
                } else {
                    setErrorMsgDialog(result.data[0].msg);
                    setErrorDialogOpen(true);
                }
            } else if (res.status === 401) {
                handleAuthError(res);
            } else if (res.status === 500) {
                handleServerError(res);
            }
        } catch (error) { }
    }

    async function handleAuthError(res: Response) {
        const result = await res.json();
        if (process.env.REACT_APP_IS_DEBUG === "false") {
            redirectToSessionExpired();
        }
        setErrorMsgDialog(result.data[0].msg);
        setErrorDialogOpen(true);
    }

    async function handleServerError(res: Response) {
        const result = await res.json();
        setErrorMsgDialog(result.data[0].msg);
        setErrorDialogOpen(true);
    }

    return (
        <Fragment>
            {!isLogginLoaded || (showVolumeRichiesto && !volumiLoaded) ? (
                <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Container maxWidth="xl" sx={{ minHeight: '90vh', display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box sx={{ mt: 2, mb: 5 }}>
                        {process.env.REACT_APP_IS_DEBUG === "true" && (
                            <Alert sx={{ mb: 2 }} severity="warning">
                                Attualmente sei in modalità <b>debug</b>
                            </Alert>
                        )}
                        <Typography sx={{ mb: 2 }} variant="h2">
                            I Tuoi simulatori
                        </Typography>
                        <Grid container direction="row" justifyContent="start" alignItems="stretch" spacing={3}>
                            {volumiLoaded
                                ? listVolumi.map((volume, index) => (
                                    <Fragment key={index}>
                                        <DialogVolume volume={volume} volumiLoaded={volumiLoaded} key={index}
                                            utente={utente} isbnVolumeRichiesto={volumeRichiesto} showVolumeRichiesto={showVolumeRichiesto} />
                                    </Fragment>
                                ))
                                : !showVolumeRichiesto &&
                                listVolumiPlaceholder.map((volume, index) => (
                                    <Grid item xs={12} md={4} lg={3} xl={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box sx={{
                                            height: { xs: '450px', md: '400px', xl: '500px' }, border: '1px solid #003370', borderRadius: '5px',
                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <CircularProgress />
                                                <Typography sx={{ mt: 3 }}>Caricamento volume...</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                        </Grid>

                        {process.env.REACT_APP_IS_DEBUG === "true" && (
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ my: 2 }} variant="h4">
                                    Opzioni sviluppatore
                                </Typography>
                                <Button disabled variant="outlined" fullWidth sx={{ my: 1 }} endIcon={<Person2Outlined />}>
                                    Cambia token utente
                                </Button>
                                <Button onClick={openDialogQuestionarioGratuito} variant="outlined" fullWidth endIcon={<LinkOutlined />} >
                                    Prova questionario gratuito
                                </Button>
                                <Button onClick={abilitaAlertCookieHandler} variant="outlined" fullWidth sx={{ my: 1 }} endIcon={<CookieOutlined />} >
                                    Abilita alert cookie
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Footer />
                </Container>
            )}

            <Dialog open={errorDialogOpen}>
                <DialogContent>
                    <Typography>{errorMsgDialog}</Typography>
                    {process.env.REACT_APP_IS_DEBUG && (
                        <>
                            <Button sx={{ mt: 2 }} color='error' variant='outlined' onClick={() => cleanLocalStorageAndReloadDebug(false)} fullWidth >
                                Pulisci localstorage e ricarica pagina
                            </Button>
                            <Button sx={{ mt: 2 }} color='error' variant='text' onClick={() => cleanLocalStorageAndReloadDebug(true)} fullWidth  >
                                Pulisci localstorage (ma mostra alert cookie) e ricarica pagina
                            </Button>
                        </>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="lg" open={dialogTestQuestionarioGratuitoOpen} onClose={openDialogQuestionarioGratuito}>
                <DialogTitle>Prova un questionario gratuito</DialogTitle>
                <DialogContent>
                    <Typography>
                        Cliccando sul pulsante di conferma si aprirà una nuova scheda nel browser e verrai rimandato al seguente
                        link:
                    </Typography>
                    <Typography sx={{ mb: 2, textDecoration: 'underline' }}>
                        {`${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_PREFIX}/sim/`}<b>{linkQuestionarioGratuito}</b>
                    </Typography>
                    {hasLinkQuestionarioGratuitoError && (
                        <Alert severity="error" sx={{ mb: 3 }}>
                            Non esiste nessun questionario gratuito con questo link. Controlla che lo stato del questionario sia
                            "<b>pubblicato</b>"
                        </Alert>
                    )}

                    <TextField fullWidth label="Link questionario gratuito" variant="outlined" onChange={changeLinkQuestionarioGratuito} />

                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'end', mt: 2 }}>
                        <Button disabled={isCheckingPermalink} variant="outlined" onClick={openDialogQuestionarioGratuito}>
                            Chiudi finestra
                        </Button>
                        <Button disabled={isCheckingPermalink} variant="contained" onClick={permalinkQuestionarioGratuitoCheck} sx={{ ml: 1 }}>
                            Conferma e prosegui
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}


type DashboardProps = {
    abilitaAlertCookie?: () => void;
};

type User = {
    id?: string;
    ruolo?: string;
    email?: string;
    nome?: string;
};
