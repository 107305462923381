import { GetFaqResponse, GetVolumiSuggeritiResponse, PostArchiveQuestionarioResponse } from "src/types/questionario.types";
import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const QuestionarioAPI = {
    loginWp: async function (obj: any, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `auth/loginWP`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(obj),
            signal: cancel ? cancelApiObject["loginWp"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    isLoggedIn: async function (cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `utente/isLoggedIn`,
            method: "GET",
            signal: cancel ? cancelApiObject["isLoggedIn"].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    logout: async function (cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `utente/logout`,
            method: "POST",
            signal: cancel ? cancelApiObject["logout"].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    getFaq: async function (obj: { idVolume: number }, cancel: boolean = false): Promise<GetFaqResponse> {
        const response = await api.request({
            url: `volume/FAQList`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: obj,
            signal: cancel ? cancelApiObject["getFaq"].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    getQuestionarioGratuito: async function (id: any, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `questionario/getGratuito/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject["getQuestionarioGratuito"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    questionarioByISBN: async function (isbn: any, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `questionario/list/byISBN/${isbn}`,
            method: "GET",
            signal: cancel ? cancelApiObject["questionarioByISBN"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    getPurchased: async function (cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `questionario/list/purchased`,
            method: "GET",
            signal: cancel ? cancelApiObject["getPurchased"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    archive: async function (obj: any, cancel: boolean = false): Promise<PostArchiveQuestionarioResponse> {
        const response = await api.request({
            url: `questionario/archive/add`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: obj,
            signal: cancel ? cancelApiObject["archiveGratuito"].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    archiveGratuito: async function (obj: any, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `questionario/archive/gratuito`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: obj,
            signal: cancel ? cancelApiObject["archiveGratuito"].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    getOgQuestionarioSSR: async function (idCondivisione:number, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `questionario/ssrOg/${idCondivisione}`,
            method: "GET",
            signal: cancel ? cancelApiObject["getOgQuestionarioSSR"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
    getManualiSuggeriti: async function (idCondivisione:number, cancel: boolean = false): Promise<GetVolumiSuggeritiResponse> {
        const response = await api.request({
            url: `materia/next/${idCondivisione}`,
            method: "GET",
            signal: cancel ? cancelApiObject["getManualiSuggeriti"].handleRequestCancellation().signal : undefined,
        });

        return response.data;
    },
};

const cancelApiObject: any = defineCancelApiObject(QuestionarioAPI)
