import React, { useEffect, useRef, useState } from "react";
import { Typography, Skeleton, Box } from "@mui/material";
import { ManualeSuggerito } from "src/types/questionario.types";

export default function VolumiConsigliati(props: Props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const caroselloRef = useRef<HTMLDivElement>(null);
    const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

    // Controlla il caricamento iniziale dei dati
    useEffect(() => {
        setIsLoaded(false);
        if (props.listManualiSuggeriti.length > 0) {
            const promises = props.listManualiSuggeriti.map((manuale) =>
                new Promise((resolve) => {
                    const img = new Image();
                    img.src = manuale.copertina;
                    img.onload = resolve;
                    img.onerror = resolve;
                })
            );
            Promise.all(promises).then(() => {
                setAllImagesLoaded(true);
                setIsLoaded(true);
            });
        } else {
            setAllImagesLoaded(false);
        }
    }, [props.listManualiSuggeriti]);


    /* useEffect(() => {
         if (isLoaded && caroselloRef.current) {
             const scrollContainer = caroselloRef.current;
 
             scrollIntervalRef.current = setInterval(() => {
                 const maxScrollOffset =
                     scrollContainer.scrollWidth - scrollContainer.clientWidth;
 
                 if (scrollContainer.scrollLeft >= maxScrollOffset) {
                     // Torna all'inizio se raggiunge il fondo
                     scrollContainer.scrollTo({ left: 0, behavior: "smooth" });
                 } else {
                     // Scorri verso il prossimo elemento
                     scrollContainer.scrollBy({
                         left: 150, // Scorri in base alla larghezza del volume
                         behavior: "smooth",
                     });
                 }
             }, 3000); // Intervallo dello scroll
 
             return () => {
                 if (scrollIntervalRef.current) clearInterval(scrollIntervalRef.current);
             };
         }
     }, [isLoaded]);*/

    function renderSkeletons() {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { sm: "row", md: "column" },
                    gap: 2,
                    justifyContent: { xs: 'space-between', md: 'center' },
                    overflowX: { xs: "auto", md: "hidden" },
                    overflowY: { md: "auto" },
                    width: "100%",
                    alignItems: "start",
                }}
            >
                {Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rectangular"
                        sx={{
                            width: { xs: "100px", md: "100px" },
                            height: "150px",
                            flexShrink: 0,
                            borderRadius: 1,
                            mr: { xs: 2, md: 0 },
                            mb: { md: 2 },
                        }}
                        animation="wave"
                    />
                ))}
            </Box>
        );
    }

    function renderCarosello() {
        return (
            <Box
                ref={caroselloRef}
                sx={{
                    display: "flex",
                    justifyContent: { xs: 'space-between', md: 'unset' },
                    flexDirection: { xs: "row", md: "column" },
                    overflowX: { xs: "auto", md: "hidden" },
                    overflowY: { xs: 'hidden', md: "auto" },
                    width: "100%",
                    maxHeight: { xs: "150px", md: "80vh" },

                }}
            >
                {props.listManualiSuggeriti.map((manuale, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: "0 0 auto",
                            width: { sm: "100px", md: "100%" },
                            height: "150px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            //p: 1,
                            mt: { xs: 1, md: 2 },
                            mr: { xs: 2, md: 0 },
                            mb: { xs: 0, md: 2 },
                        }}
                    >
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={manuale.linkAcquisto}
                            style={{width:'100%', height: "100%", display: "flex", justifyContent: "start" }}
                        >
                            <img
                            draggable="false"
                                unselectable="on"
                                alt={manuale.titolo}
                                src={manuale.copertina}
                                loading="lazy"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                    margin: 0,
                                }}
                            />
                        </a>
                    </Box>
                ))}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                px: 1,
                position: "sticky",
                top: 20,
                maxHeight: "calc(100vh - 40px)",
                overflowY: { md: "auto" },
            }}
        >
            <Typography variant="h6" sx={{ mb: 2, textAlign: { xs: 'center', md: "start" } }}>
                Prova anche
            </Typography>
            {!isLoaded ? renderSkeletons() : renderCarosello()}
        </Box>
    );
}

interface Props {
    listManualiSuggeriti: ManualeSuggerito[];
}
