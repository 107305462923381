import { Close, Warning } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { SocialAPI } from "src/api/socialApi";
import { useSnackbar } from "src/context/snackbarContext";

export default function Segnalazione(props: Props) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    async function sendSegnalazione() {
        const dataObj = {
            idQuestionario: props.idQuestionario,
            idThread: props.idThread,
            idCommento: props?.idCommento ?? null
        };
        try {
            setIsLoading(true);
            await SocialAPI.sendSegnalazione(dataObj);
            setIsDialogOpen(false);
            showSnackbar("Segnalazione inviata", "success");
            props.segnalazioneInviata();
        } catch (error: any) {
            const errorMsg: string =
                error?.response?.data?.data
                    ? error.response.data.data[0]?.msg
                    : "Errore durante l'invio della segnalazione";
            showSnackbar(errorMsg, "error");
        } finally {
            setIsLoading(false);
        }
    }

    function openDialog() {
        setIsDialogOpen(true);
    }

    function closeDialog() {
        setIsDialogOpen(false);
    }

    return (
        <Fragment>
            {/* Bottone per aprire il dialog */}
            <IconButton disabled={props.utenteHasSegnalazione} onClick={openDialog} color="warning">
                <Warning />
            </IconButton>

            {/* Dialog per inviare la segnalazione */}
            <Dialog fullWidth maxWidth="sm" open={isDialogOpen} onClose={closeDialog}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="span">
                            Invia segnalazione
                        </Typography>
                        <IconButton disabled={isLoading} onClick={closeDialog} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Vuoi davvero segnalare questo {props.idCommento ? "commento" : "post"}?
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", mt: 2 }}>
                        <Button variant="text" sx={{ mr: 1 }} onClick={closeDialog}>
                            Annulla
                        </Button>
                        <Button disabled={isLoading} variant="contained" color="success" onClick={sendSegnalazione}>
                            {isLoading ? "Invio in corso..." : "Conferma segnalazione"}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

interface Props {
    segnalazioneInviata: () => void;
    utenteHasSegnalazione: boolean;
    idQuestionario: number;
    idThread: number;
    idCommento?: number;
}
