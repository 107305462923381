import { Close, Refresh, Star } from "@mui/icons-material";
import {
    Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle,
    Grid,
    IconButton,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, useTheme,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { SocialAPI } from "src/api/socialApi";
import { ClassificaObj } from "src/types/questionario.types";


export default function ClassificaRiepilogo(props: Props) {

    const [isClassificaLoaded, setIsClassificaLoaded] = useState(false);
    const [objClassifica, setObjClassifica] = useState<ClassificaObj | null>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const rowRefs = useRef<HTMLTableRowElement[]>([]); // Riferimenti alle righe
    const theme = useTheme();

    useEffect(() => {
        if (props.idQuestionario !== -1)
            getClassifica();
    }, [props.idQuestionario]);


    useEffect(() => {
        if (objClassifica) {
            const timeout = setTimeout(() => tableContainerRef.current && scrollToPosition(), 0);
            return () => clearTimeout(timeout);
        }
    }, [objClassifica]);

    async function getClassifica() {
        setIsClassificaLoaded(false);
        try {
            const result = await SocialAPI.getClassifica(props.idQuestionario);
            if (result.success) setObjClassifica(result.data);
            else throw new Error("Errore durante il recupero dei dati");
        } catch (error) {
            console.error(error);
        } finally {
            setIsClassificaLoaded(true);
        }
    }

    function scrollToPosition() {
        if (objClassifica && tableContainerRef.current && objClassifica.posto) {
            const container = tableContainerRef.current, targetIndex = objClassifica.posto - 1;
            const targetRow = rowRefs.current[targetIndex];
            if (targetRow)
                container.scrollTop = targetRow.offsetTop - container.offsetHeight / 2 + targetRow.clientHeight / 2;
        }
    }

    return (
        <Paper elevation={4} sx={{ mt: 2, px: 5, py: 2 }}>
            <Grid container spacing={2}>
                {isClassificaLoaded ? (
                    <Fragment>
                        <Grid item xs={12} md={6}>

                            {props.type === "Sim" ? (
                                objClassifica && objClassifica?.classifica.length > 0 ? (
                                    <Fragment>
                                        <TableContainer
                                            component={Paper}
                                            ref={tableContainerRef}
                                            sx={{
                                                maxHeight: "45vh",
                                                overflowY: "auto",
                                                borderRadius: 0,
                                                boxShadow: "none",
                                            }}
                                        >
                                            <Table aria-label="tabella classifica">
                                                <TableHead>
                                                    <TableRow
                                                        sx={{
                                                            position: "sticky",
                                                            top: 0,
                                                            zIndex: 1,
                                                            backgroundColor: theme.palette.background.paper,
                                                        }}
                                                    >
                                                        <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}></TableCell>
                                                        <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}>Punteggi</TableCell>
                                                        <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}>Utenti</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {objClassifica.classifica.map((row, index) => (
                                                        <TableRow
                                                            key={row.ordinale}
                                                            ref={(el) => (rowRefs.current[index] = el!)}
                                                            sx={{
                                                                backgroundColor: row.posizione ? "primary.main" : "inherit",
                                                                color: row.posizione
                                                                    ? theme.palette.primary.contrastText
                                                                    : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            <TableCell
                                                                align="center"
                                                                sx={{
                                                                    border: "1px solid #ccc",
                                                                    color: row.posizione
                                                                        ? theme.palette.primary.contrastText
                                                                        : theme.palette.text.primary,
                                                                }}
                                                            >
                                                                {row.ordinale}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                sx={{
                                                                    border: "1px solid #ccc",
                                                                    color: row.posizione
                                                                        ? theme.palette.primary.contrastText
                                                                        : theme.palette.text.primary,
                                                                }}
                                                            >
                                                                {row.punteggio}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                sx={{
                                                                    border: "1px solid #ccc",
                                                                    color: row.posizione
                                                                        ? theme.palette.primary.contrastText
                                                                        : theme.palette.text.primary,
                                                                }}
                                                            >
                                                                {row.utenti}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Fragment>
                                ) : (
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: '100%' }}>
                                        <Typography textAlign={"center"}>
                                            La classifica non è disponibile per questo simulatore.
                                        </Typography>
                                    </Box>
                                )
                            ) : (
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: '100%' }}>

                                    <Typography textAlign={"center"}>La classifica è disponibile solo nella simulazione</Typography>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", height: '100%', px: { xs: 0, md: 5 } }}>
                                {(objClassifica && objClassifica.classifica && objClassifica.classifica.length > 0 && props.type === "Sim") && (
                                    <Fragment>
                                        <Typography sx={{ mt: 2, textAlign: "center" }}>
                                            Sei al <b>{objClassifica?.posto ?? "-"}° posto</b> insieme ad altre{" "}
                                            {objClassifica?.classifica?.[
                                                objClassifica?.posto && objClassifica.posto - 1 >= 0
                                                    ? objClassifica.posto - 1
                                                    : 0
                                            ]?.utenti ?? "0"}{" "}
                                            persone
                                        </Typography>
                                        <Typography sx={{ my: 2 }}>I tuoi risultati stanno migliorando!</Typography>
                                    </Fragment>
                                )}
                                <Button onClick={props.riavviaSimulatore} sx={{ mt: 1 }} endIcon={<Refresh />} fullWidth variant="contained" >
                                    Riavvia simulazione
                                </Button>
                                <Button onClick={props.gotListaQuestionari} sx={{ mt: 2 }} fullWidth variant="outlined"  >
                                    Lista simulatori
                                </Button>
                                <Button onClick={props.goToListaVolumi} sx={{ mt: 1 }} fullWidth variant="outlined">
                                    Lista volumi
                                </Button>
                            </Box>

                        </Grid>

                    </Fragment>
                ) : (
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

interface Props {
    riavviaSimulatore: () => void
    goToListaVolumi: () => void
    gotListaQuestionari: () => void
    idQuestionario: number,
    type: string
}