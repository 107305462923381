import { faFacebookF, faLinkedinIn, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Email, Facebook, LinkedIn, X } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";

export default function CondividiRisultato(props: Props) {

    function handleShare(socialType: string) {
        const baseUrl = process.env.REACT_APP_FETCH_URL;
        let newUrl = '';

        const descrizione = `Ho ottenuto il punteggio di ${props.risposteCorrette}/${props.punteggioTotale} alla simulazione ${props.nomeQuestionario}`

        switch (socialType) {
            case 'facebook':
                newUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    `${baseUrl}questionario/condividi/${props.idCondivisione}`
                )}`;
                break;
            case 'linkedin':
                newUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                    `${baseUrl}questionario/condividi/${props.idCondivisione}`
                )}&title=${descrizione}`;
                break;
            case 'x':
                newUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    `${baseUrl}questionario/condividi/${props.idCondivisione}`
                )}&text=${descrizione}`;
                break;
            default:
                return;
        }

        window.open(newUrl, '_blank', 'noopener,noreferrer');
    }

    return (
        <Box
            sx={{
                my: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    mb: { xs: 2, md: 0 }, // Margine inferiore su xs/sm, nessun margine su md+
                    mr: { xs: 0, md: 2 }, // Margine destro su md+
                    textAlign: { xs: "center", md: "left" }, // Testo centrato su xs/sm
                }}
            >
                Condividi il tuo risultato
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton
                    onClick={() => handleShare("linkedin")}
                    color="primary"
                    size="medium"
                    sx={{ mr: 1, width: "56px", height: "56px" }}
                >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </IconButton>
                <IconButton
                    onClick={() => handleShare("facebook")}
                    color="primary"
                    size="medium"
                    sx={{ mr: 1, width: "56px", height: "56px" }}
                >
                    <FontAwesomeIcon icon={faFacebookF} />
                </IconButton>
                <IconButton
                    onClick={() => handleShare("x")}
                    color="primary"
                    size="medium"
                    sx={{ width: "56px", height: "56px" }}
                >
                    <FontAwesomeIcon icon={faXTwitter} />
                </IconButton>
            </Box>
        </Box>

    )
}

interface Props {
    risposteCorrette: number,
    punteggioTotale: number
    nomeQuestionario: string
    idCondivisione: number
}