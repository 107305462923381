import { CheckBox, Close } from "@mui/icons-material";
import { Box, Dialog, TextField, Button, DialogContent, DialogTitle, IconButton, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { Fragment, useState } from "react";
import { SocialAPI } from "src/api/socialApi";
import { useSnackbar } from "src/context/snackbarContext";
import { PostObj } from "src/types/questionario.types";
import { errorResponse } from "src/types/utility.types";

export default function CreateNewPost(props: Props) {

    const [titolo, setTitolo] = useState("");
    const [testoPost, setTestoPost] = useState("");
    const [utenteWantsNotifiche, setUtenteWantsNotifiche] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [isCreatingPost, setIsCreatingPost] = useState(false);
    const { showSnackbar } = useSnackbar();

    function changeTitolo(e: any) {
        setTitolo(e.target.value);
    }

    function changeTestoPost(e: any) {
        setTestoPost(e.target.value);
    }

    async function createNewPost() {
        setIsCreatingPost(true);
        const dataObj = {
            idQuestionario: props.idQuestionario,
            titolo: titolo,
            testo: testoPost,
            ricezioneEmail: utenteWantsNotifiche
        };
        let result = null;
        try {
            result = await SocialAPI.saveNewPost(dataObj);
            setTestoPost("");
            setTitolo("");
            setUtenteWantsNotifiche(false);
        } catch (error: any | errorResponse) {
            const errorMsg: string = error?.response?.data?.data ? error?.response?.data?.data[0]?.msg : "Errore durante la creazione del post";
            showSnackbar(errorMsg, "error");
        } finally {
            if (result && result.success && result.data) {
                const objNewPostUpdated = {
                    ...result.data,
                    listCommenti: []
                };
                setIsDialogOpen(false);
                props.postCreated(objNewPostUpdated);
            }
            setIsCreatingPost(false);
        }
    }

    return (
        <Fragment>
            <Box sx={{ flexShrink: 0 }}>
                <Button sx={{height:'56px'}} onClick={() => setIsDialogOpen(true)} variant="contained" disabled={props.isSearchingPosts}>
                    Crea post
                </Button>
            </Box>
            <Dialog fullWidth maxWidth="sm" open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="span">
                            Crea nuovo post
                        </Typography>
                        <IconButton disabled={isCreatingPost} onClick={() => setIsDialogOpen(false)} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <Box>
                        <TextField required fullWidth value={titolo} onChange={changeTitolo} placeholder="Titolo del post" />
                        <TextField required sx={{ my: 2 }} fullWidth value={testoPost} onChange={changeTestoPost} placeholder="Scrivi qui..." multiline rows={3} />
                        <FormControlLabel sx={{ mb: 2 }}
                            control={
                                <Checkbox
                                    onChange={() => setUtenteWantsNotifiche((prev) => !prev)}
                                    checked={utenteWantsNotifiche}
                                />
                            }
                            label="Vuoi ricevere email di notifica quando un utente interagisce con il tuo post? "
                        />
                        <Button disabled={isCreatingPost || testoPost === "" || titolo === ""} onClick={createNewPost} fullWidth variant="contained">Crea nuovo post</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

interface Props {
    postCreated: (newPostObj: PostObj) => void;
    idQuestionario: number,
    isSearchingPosts: boolean
}