import CondividiRisultato from "../components/statistiche-questionario/condividiRisultato";
import ClassificaRiepilogo from "../components/classifica/classificaRiepilogo";
import EsitoAndGrafico from "../components/statistiche-questionario/esitoAndGrafico";
import RowDomandaRiepilogoQuestionario from "../components/statistiche-questionario/rowDomandaRiepilogoQuestionario";
import { ArrowBack, Email, ExpandLess, ExpandMore, Facebook, Instagram, RestartAlt, YouTube } from "@mui/icons-material";
import { Box, Button, Checkbox, Collapse, Container, Divider, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import { QuestionarioAPI } from "src/api/questionarioApi";
import { RiepilogoQuizSvolti } from "./riepilogoQuizSvolti";
import logo from '../assets/edizioni-simone-logo-blu.png';
import VolumiConsigliati from "../components/statistiche-questionario/volumiConsigliati";
import { ManualeSuggerito } from "src/types/questionario.types";

export default function StatisticheQuizSvoltoNew(props: Props) {

  const navigate = useNavigate()

  const [tempoDiCompletamento, setTempoDiCompletamento] = useState("");
  const [risposteCorrette, setRisposteCorrette] = useState(0);
  const [risposteErrate, setRisposteErrate] = useState(0);
  const [risposteOmesse, setRisposteOmesse] = useState(0);
  const [punteggio, setPunteggio] = useState(0);
  const [nRisposteTotali, setNRisposteTotali] = useState(0);
  const [esito, setEsito] = useState(false);

  const [showRiepilogoDomande, setShowRiepilogoDomande] = useState(false);
  const [emailPageOpen, setEmailPageOpen] = useState(true);
  const [emailUtente, setEmailUtente] = useState("");
  const [consensoNewsletter, setConsensoNewsletter] = useState(false);
  const [consensoPrivacy, setConsensoPrivacy] = useState(false);
  const [isRiepilogoCollapsed, setIsRiepilogoCollapsed] = useState(true)

  const [idCondivisione, setIdCondivisione] = useState(-1);
  const [canLoadClassifica, setCanLoadClassifica] = useState(false);
  const [listManualiSuggeriti, setListManualiSuggeriti] = useState<ManualeSuggerito[]>([]);

  useEffect(() => {
    if (props.dataInizio !== null && props.dataFine !== null) {
      const diffMilliseconds = props.dataFine - props.dataInizio;
      const diffSeconds = Math.floor(diffMilliseconds / 1000);
      const diffMinutes = Math.floor(diffSeconds / 60);
      const diffHours = Math.floor(diffMinutes / 60);

      const seconds = (diffSeconds % 60).toString().padStart(2, "0");
      const minutes = (diffMinutes % 60).toString().padStart(2, "0");
      const hours = diffHours.toString();

      const tempoCompletamentoTmp = `${hours}:${minutes}:${seconds}`;
      setTempoDiCompletamento(tempoCompletamentoTmp);
    }
  }, [props.dataInizio, props.dataFine]);

  useEffect(() => {
    if (props.questionario) {
      const { corrette, errate, omesse } = calculateStatistiche();
      setRisposteCorrette(corrette);
      setRisposteErrate(errate);
      setRisposteOmesse(omesse);
      setNRisposteTotali(corrette + errate + omesse);
      if (!props.isGratuito)
        saveQuestionario(corrette, errate, omesse);
    }
  }, [props.questionario]);

  function changeEmailUtente(e: any) {
    let emailTmp = e.target.value;
    emailTmp = emailTmp.replace(/\s+/g, '')
    setEmailUtente(emailTmp);
  }

  function changePage() {
    navigate("/sim", { state: { idQuestionario: props.idQuestionario, idUtente: props.idUtente } })
  }
  function goToListaVolumi() {
    navigate("/home", { state: { volumeSelezionato: false } })
  }
  function gotListaQuestionari() {
    navigate("/home", { state: { volumeSelezionato: true } })
  }

  function calculateStatistiche() {
    let corrette = 0;
    let errate = 0;
    let omesse = 0;

    props.questionario.forEach((quiz: any) => {
      if (quiz.givenAnswer.some((item: any) => quiz.correctAnswer.includes(item))) {
        corrette++;
      } else if (quiz.givenAnswer.length > 0) {
        errate++;
      } else {
        omesse++;
      }
    });

    return { corrette, errate, omesse };
  }

  async function saveQuestionario(risposteCorretteTmp: number, risposteErrateTmp: number, risposteOmesseTmp: number) {
    let utenteIDTmp = props.idUtente;
    const storedId = localStorage.getItem("id");
    if (storedId !== null) {
      utenteIDTmp = storedId;
    }
    const dataInizioTimeStamp = new Date(props.dataInizio).toISOString();
    const dataFineTimeStamp = new Date(props.dataFine).toISOString();
    const dataObj = {
      sogliaSufficienza: props.sogliaSufficienza,
      nomeQuestionario: props.nomeQuestionario,
      idQuestionario: parseInt(props.idQuestionario),
      idVolume: props.idVolume,
      idUtente: `${utenteIDTmp}`,
      superato: esito,
      type: props.type,
      risposteOmesse: risposteOmesseTmp,
      risposteErrate: risposteErrateTmp,
      risposteCorrette: risposteCorretteTmp,
      quiz: props.questionario,
      dataInizio: dataInizioTimeStamp,
      dataFine: dataFineTimeStamp,
    };

    try {
      const dataObjJson = JSON.stringify(dataObj);
      const result = await QuestionarioAPI.archive(dataObjJson);

      let punteggioTotaleTmp = (result?.data?.punteggioTotale ?? 0).toString();
      const indiceVirgola = punteggioTotaleTmp.indexOf(".");
      if (indiceVirgola !== -1) {
        punteggioTotaleTmp = punteggioTotaleTmp.substring(0, indiceVirgola + 3).padEnd(indiceVirgola + 3, "0");
      }
      setIdCondivisione(result?.data?.idCondivisione ? parseInt(result?.data?.idCondivisione) : -1);
      setEsito(result?.data?.superato ?? false);
      setPunteggio(parseFloat(punteggioTotaleTmp) ?? 0);
      setRisposteCorrette(risposteCorretteTmp);
      getManualiSuggeriti();
      setCanLoadClassifica(true);
    } catch (err: any) {
      console.error("Errore durante il salvataggio del questionario:", err);
    }
  }
  async function saveQuestionarioGratuito() {
    const dataInizioTimeStamp = new Date(props.dataInizio).toISOString();
    const dataFineTimeStamp = new Date(props.dataFine).toISOString();
    let data = {
      sogliaSufficienza: props.sogliaSufficienza,
      nomeQuestionario: props.nomeQuestionario,
      idQuestionario: parseInt(props.idQuestionario),
      idVolume: props.idVolume,
      superato: esito,
      type: props.type,
      risposteOmesse: risposteOmesse,
      risposteErrate: risposteErrate,
      risposteCorrette: risposteCorrette,
      quiz: props.questionario,
      dataInizio: dataInizioTimeStamp,
      dataFine: dataFineTimeStamp
    }

    let dataObj = JSON.stringify(data);
    QuestionarioAPI.archiveGratuito(dataObj)
      .then((result) => {
        //Troncamento al secondo numero dopo la virgola (non viene arrotondato)
        let punteggioTotaleTmp = (result?.data?.punteggioTotale ?? 0).toString();
        let indiceVirgola = punteggioTotaleTmp.indexOf('.');
        if (indiceVirgola != -1) {
          punteggioTotaleTmp = punteggioTotaleTmp.substring(0, indiceVirgola + 3);
          while (punteggioTotaleTmp.length < indiceVirgola + 3) {
            punteggioTotaleTmp += '0';
          }
        }
        setPunteggio(punteggioTotaleTmp ?? 0);
        setEsito(result?.data?.superato ?? false)
      })
      .catch((err) => { })

  }
  async function saveNewEmail() {
    try {
      const body = new URLSearchParams({
        id_questionario: props.idQuestionario.toString(),
        email: emailUtente,
        consenso_privacy: consensoPrivacy.toString(),
        consenso_newsletter: consensoNewsletter.toString(),
      });

      const requestOptions = {
        method: 'POST',
        body,
      };

      const response = await fetch(
        `${process.env.REACT_APP_FETCH_URL}contattoMailingList/add`,
        requestOptions
      );

      const result = await response.json();

      if (result.success) {
        setEmailPageOpen(false);
        saveQuestionarioGratuito();
      } else {
        console.error('Errore durante il salvataggio:', result.message);
      }
    } catch (error) {
      console.error('Errore nella chiamata API:', error);
    }
  }
  async function getManualiSuggeriti() {
    try {
      const result = await QuestionarioAPI.getManualiSuggeriti(parseInt(props.idQuestionario));
      setListManualiSuggeriti([...result.data.suggeriti]);
    } catch {
      setListManualiSuggeriti([]);
    }
  }


  return (
    <Fragment>
      {(emailPageOpen === true && props.isGratuito) ? (
        <Box sx={{ height: '80vh' }} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          <Box sx={{ mb: 5 }} display="flex" justifyContent={"center"}>
            <Link target="_blank" href="https://edizioni.simone.it/" underline="none">
              <img unselectable="on" draggable="false" alt="Logo Simone Edizioni" width={300} src={logo}></img>
            </Link>
          </Box>

          <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography sx={{ mb: 2, fontWeight: 'normal' }} variant="body1">Per vedere i risultati del simulatore è necessario inserire il proprio indirizzo email e accettare l'informativa sulla privacy.</Typography>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>Il tuo indirizzo email*</Typography>
            <TextField sx={{ width: '100%' }} type="email" placeholder="Campo obbligatorio" value={emailUtente} onChange={changeEmailUtente} InputProps={{
              startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
            }} />
            {emailUtente !== '' && !emailUtente.includes("@") && (
              <Typography sx={{ color: 'red', ml: 1, mt: 1 }}>Per proseguire è necessario inserire un'email valida</Typography>
            )}
            <Box sx={{ ml: 2, mt: 1, display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={consensoPrivacy} onChange={() => setConsensoPrivacy(prev => !prev)} inputProps={{ 'aria-label': 'controlled' }} />
              <Typography> Dichiaro di aver letto<a target="_blank" rel="noreferrer" href='https://edizioni.simone.it/informativa-sulla-privacy'> l'informativa sul trattamento dei miei dati personali.</a> <b>(Campo obbligatorio)</b></Typography>
            </Box>
            <Box sx={{ ml: 2, mt: 1, display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={consensoNewsletter} onChange={() => setConsensoNewsletter(prev => !prev)} inputProps={{ 'aria-label': 'controlled' }} />
              <Typography>  Acconsento a ricevere offerte speciali, promozioni e comunicazioni commerciali da parte del<a rel="noreferrer" target="_blank" href='https://edizioni.simone.it/'> Gruppo Simone.</a></Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button disabled={consensoPrivacy === false || emailUtente === '' || !emailUtente.includes('@')} onClick={saveNewEmail} variant="contained">Conferma e prosegui</Button>
            </Box>
          </Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', }}>
            <Divider sx={{ mt: 5, mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Link target="_blank" href="https://edizioni.simone.it/" underline="none">
                <img unselectable="on" draggable="false" alt="Logo Simone Edizioni" width={100} src={logo}></img>
              </Link>
              <Box sx={{ display: 'flex', alignItems: 'center', color: '#003370' }}>
                <Typography variant='subtitle2' sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>Seguici sui nostri social:</Typography>
                <Link target="_blank" href="https://it-it.facebook.com/EdizioniSimone/" underline="none">
                  <Facebook sx={{ fontSize: '2rem' }} />
                </Link>
                <Link target="_blank" href="https://www.instagram.com/edizionisimone/?hl=it" underline="none">
                  <Instagram sx={{ fontSize: '2rem', mx: 2 }} />
                </Link>
                <Link target="_blank" href="https://www.youtube.com/channel/UC7Sl8bFGpJMiilhozwnMBnQ" underline="none">
                  <YouTube sx={{ fontSize: '2.5rem' }} />
                </Link>

              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Fragment>
          {showRiepilogoDomande ? (
            <Fragment>
              <Box sx={{ height: { xs: '75vh', md: '80vh' }, border: '1px solid #00000021', borderRadius: '10px' }}>
                <Virtuoso
                  style={{ height: '100%' }}
                  totalCount={props.questionario.length}
                  itemContent={(index: number) => (
                    <RowDomandaRiepilogoQuestionario
                      quiz={props.questionario[index]}
                      index={index}
                    />
                  )}
                />
              </Box>
              <Box
                display="flex"
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { md: 'center' },
                  justifyContent: { md: 'space-between' },
                  mt: { xs: 2, md: 4 },
                }}
              >
                {showRiepilogoDomande && (
                  <Button sx={{ width: { xs: '100%', md: 'auto' }, px: 5, mb: { xs: 1, md: 0 }, mr: { md: 2 }, }} variant="outlined"
                    onClick={() => setShowRiepilogoDomande(false)} startIcon={<ArrowBack />} >
                    Torna alle statistiche
                  </Button>
                )}
                {props.isGratuito === false && (
                  <Box >
                    <Box display={{ xs: 'block', md: 'none' }} >
                      <Button fullWidth variant="contained" onClick={changePage} startIcon={<RestartAlt />}>Ripeti simulatore</Button>
                      <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 1 }} onClick={gotListaQuestionari} >Lista simulatori</Button>
                      <Button fullWidth variant="outlined" onClick={goToListaVolumi} >Lista volumi</Button>
                    </Box>
                    <Box display={{ xs: 'none', md: 'flex' }} >
                      <Button sx={{ px: 5 }} variant="outlined" onClick={gotListaQuestionari} >Lista simulatori</Button>
                      <Button sx={{ px: 5, mx: 1 }} variant="outlined" onClick={goToListaVolumi}>Lista volumi</Button>
                      <Button sx={{ px: 5 }} variant="contained" onClick={changePage} startIcon={<RestartAlt />}>Ripeti simulatore</Button>
                    </Box>
                  </Box >
                )}
              </Box>

            </Fragment>
          ) : (
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <VolumiConsigliati listManualiSuggeriti={listManualiSuggeriti} />
                </Grid>
                <Grid item xs={12} md={10}>
                  <EsitoAndGrafico risposteCorrette={risposteCorrette} risposteOmesse={risposteOmesse} risposteErrate={risposteErrate} idQuestionario={props.idQuestionario} isQuestionarioGratuito={props.isGratuito}
                    esito={esito} tempoCompletamento={tempoDiCompletamento} punteggioTotale={punteggio} typeQuestionario={props.type} showRiepilogoDomande={() => setShowRiepilogoDomande(true)} />
                  {!props.isGratuito && (
                    <Fragment>
                      {props.type === "Sim" && (
                        <CondividiRisultato idCondivisione={idCondivisione} nomeQuestionario={props.nomeQuestionario} risposteCorrette={risposteCorrette} punteggioTotale={nRisposteTotali} />
                      )}
                      {canLoadClassifica && (
                        <ClassificaRiepilogo type={props.type} riavviaSimulatore={changePage} gotListaQuestionari={gotListaQuestionari} goToListaVolumi={goToListaVolumi} idQuestionario={parseInt(props.idQuestionario)} />
                      )}
                      <Paper elevation={4} sx={{ mt: 2, p: 3 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6">Riepilogo test svolti in precedenza</Typography>
                          <IconButton onClick={() => setIsRiepilogoCollapsed(prev => !prev)}>
                            {isRiepilogoCollapsed ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Box>
                        <Collapse in={isRiepilogoCollapsed}>
                          <RiepilogoQuizSvolti
                            isInRiepilogo={true}
                            idUtente={props.idUtente}
                            questionarioID={props.idQuestionario}
                          />
                        </Collapse>
                      </Paper>
                    </Fragment>
                  )}
                </Grid>
              </Grid>

            </Container>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

interface Props {
  dataInizio: number; // Timestamp
  dataFine: number; // Timestamp
  questionario: any; // Sostituisci `any` con il tipo specifico del questionario, se disponibile
  isGratuito: boolean;
  nomeQuestionario: string;
  idVolume: number;
  idUtente: any;
  idQuestionario: string;
  type: string; // Specifica il tipo se è più definito
  sogliaSufficienza: number;
}
