import { PostLikeRequest, PostSegnalazioneRequest } from "src/types/social.types"
import { BadgeListResponse, GetClassificaResponse, GetForumCommentiResponse, GetForumResponse, PostForumRequest, PostNewCommentoRequest, PostNewCommentoResponse, PostNewPostRequest, PostNewPostResponse } from "../types/questionario.types"
import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const SocialAPI = {
    getBadges: async function (obj: { idQuestionario: number }, cancel: boolean = false): Promise<BadgeListResponse> {
        const response = await api.request({
            url: `badge/list`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(obj),
            signal: cancel ? cancelApiObject["getBadges"].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getClassifica: async function (idQuestionario: number, cancel: boolean = false): Promise<GetClassificaResponse> {
        const response = await api.request({
            url: `classifica/getClassifica/${idQuestionario}`,
            method: "GET",
            signal: cancel ? cancelApiObject["getClassifica"].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getForum: async function (idQuestionario: number, obj: PostForumRequest, cancel: boolean = false): Promise<GetForumResponse> {
        const response = await api.request({
            url: `forum/${idQuestionario}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["getForum"].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getCommenti: async function (idThread: number, obj: { offset: number }, cancel: boolean = false): Promise<GetForumCommentiResponse> {
        const response = await api.request({
            url: `forum/risposte/${idThread}`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["getCommenti"].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    saveNewPost: async function (obj: PostNewPostRequest, cancel: boolean = false): Promise<PostNewPostResponse> {
        const response = await api.request({
            url: `forum/saveThread`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["saveNewPost"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveNewCommento: async function (obj: PostNewCommentoRequest, cancel: boolean = false): Promise<PostNewCommentoResponse> {
        const response = await api.request({
            url: `forum/saveCommento`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["saveNewCommento"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    saveLike: async function (obj: PostLikeRequest, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `forum/saveLike`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["saveLike"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    deleteLike: async function (obj: PostLikeRequest, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `forum/deleteLike`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["deleteLike"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    sendSegnalazione: async function (obj: PostSegnalazioneRequest, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `forum/inviaSegnalazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["deleteLike"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    changePrefenzaNotifiche: async function (obj: {idThread:number, flag:boolean}, cancel: boolean = false): Promise<any> {
        const response = await api.request({
            url: `forum/updateRicezione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject["changePrefenzaNotifiche"].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

const cancelApiObject: any = defineCancelApiObject(SocialAPI)
