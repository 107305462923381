import { ArrowBack, ChatBubbleOutline, Close, Send, ThumbUp, ThumbUpOutlined } from "@mui/icons-material";
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { formatUtcInFormatoLeggibile } from "../../utils/utilityFunction";
import { useEffect, useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { CommentoObj, PostObj } from "src/types/questionario.types";
import { SocialAPI } from "src/api/socialApi";
import CommentoPost from "./commentoPost";
import { Virtuoso } from "react-virtuoso";
import { useSnackbar } from "src/context/snackbarContext";
import Segnalazione from "./segnalazione";
import Thread from "./thread";

export default function Post(props: Props) {

    const [objThread, setObjThread] = useState<PostObj>(props.postObj)
    const [utenteWantsNotifiche, setUtenteWantsNotifiche] = useState(props.postObj.ricezioneEmail ?? false);

    const [loadingLike, setLoadingLike] = useState(false);

    const [isSectionCommentiOpen, setIsSectionCommentiOpen] = useState(false);
    const [areCommentiLoading, setAreCommentiLoading] = useState(false);

    const [textComment, setTextCommento] = useState("");
    const [commentingLoading, setCommentingLoading] = useState(false);

    const lastCommentFetchTime = useRef<number | null>(null);
    const [offsetPagination, setOffsetPagination] = useState(0);

    const [hasMoreCommenti, setHasMoreCommenti] = useState(true);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        setObjThread(props.postObj);
    }, [props.postObj]);


    async function sendCommento() {
        const dataObj = {
            idQuestionario: props.idQuestionario,
            idThread: objThread.id,
            testo: textComment,
        };

        setCommentingLoading(true);
        try {
            const result = await SocialAPI.saveNewCommento(dataObj);
            const updatedThread = {
                ...objThread,
                numeroCommenti: objThread.numeroCommenti + 1,
                listCommenti: [
                    result.data,
                    ...(objThread.listCommenti || [])
                ]
            };
            props.threadChanged(updatedThread);
            setTextCommento("");
            setCommentingLoading(false);
            showSnackbar("Commento aggiunto", "success");
        } catch (error: any) {
            const errorMsg: string = error?.response?.data?.data ? error?.response?.data?.data[0]?.msg : "Errore durante la creazione del commento";
            showSnackbar(errorMsg, "error");
        } finally {
            setCommentingLoading(false);
        }
    }

    function likePost() {
        if (!objThread) return;

        setLoadingLike(true);

        const updatedThread = {
            ...objThread,
            utenteHasLike: !objThread.utenteHasLike,
            numeroLike: !objThread.utenteHasLike ? objThread.numeroLike + 1 : objThread.numeroLike - 1,
        };

        const dataObj = {
            idQuestionario: props.idQuestionario,
            idThread: objThread.id,
            idCommento: null
        }
        updatedThread.utenteHasLike ? SocialAPI.saveLike(dataObj) : SocialAPI.deleteLike(dataObj);

        setObjThread(updatedThread);
        setTimeout(() => {
            setLoadingLike(false);
            props.threadChanged(updatedThread);
        }, 100);
    }

    function commentoChanged(commentoObj: CommentoObj, index: number) {
        if (!objThread.listCommenti) return;

        let listCommentiUpdated = objThread.listCommenti;
        listCommentiUpdated[index] = commentoObj;

        const updatedThread = {
            ...objThread,
            listCommenti: listCommentiUpdated,
        };

        setObjThread(updatedThread);

    }

    function openSectionCommenti() {
        const isSectionCommentiOpenTmp = !isSectionCommentiOpen;
        setIsSectionCommentiOpen(isSectionCommentiOpenTmp);
        if (isSectionCommentiOpenTmp) maybeGetCommenti();
    }

    async function maybeGetCommenti() {
        const now = Date.now();
        if (
            objThread.listCommenti && objThread.listCommenti.length === 0 ||
            (lastCommentFetchTime.current && now - lastCommentFetchTime.current >= 120000)
        ) {
            await getCommenti();
            lastCommentFetchTime.current = now;
        }
    }

    async function getCommenti(isInitialLoad = false) {
        if (areCommentiLoading) return;

        try {
            const dataObj = {
                offset: offsetPagination,
            };
            setAreCommentiLoading(true);
            const result = await SocialAPI.getCommenti(objThread?.id ?? 0, dataObj);

            setObjThread((prev) => {
                if (!prev) return prev;
                const objThreadUpdated = {
                    ...prev,
                    listCommenti: prev.listCommenti ? [...prev.listCommenti, ...result.data.risposte] : result.data.risposte,
                };
                setOffsetPagination((prevOffset) => prevOffset + result.data.risposte.length);
                return objThreadUpdated;
            });

            if (result.data.risposte.length === 0) {
                setHasMoreCommenti(false);
            }
        } catch (err: any) {
            console.log(err);
        } finally {
            setAreCommentiLoading(false);
        }
    }

    async function changePreferenzaNotifiche() {
        const preferenzaChanged = !utenteWantsNotifiche;
        const preferenzaNotChanged = utenteWantsNotifiche;
        setUtenteWantsNotifiche((prev) => !prev);
        try {
            const dataObj = {
                idThread: objThread.id,
                flag: preferenzaChanged
            }
            await SocialAPI.changePrefenzaNotifiche(dataObj);
            const updatedThread = {
                ...objThread,
                ricezioneEmail: preferenzaChanged,
            };

            setObjThread(updatedThread);
            showSnackbar("Preferenza cambiata", "info");
            props.threadChanged(updatedThread);
        } catch (error: any) {
            const errorMsg: string = error?.response?.data?.data ? error?.response?.data?.data[0]?.msg : "Errore durante la modifica della preferenza";
            showSnackbar(errorMsg, "error");
            setUtenteWantsNotifiche(preferenzaNotChanged);
        }
    }

    function segnalazionePostInviata(){
        const updatedThread = {
            ...objThread,
            utenteHasSegnalazione: true
        };
        setObjThread(updatedThread);
        props.threadChanged(updatedThread);
    }


    return (
        <Fragment>
            <Box sx={{ mb: 5 }}>
            <Thread
                    objThread={objThread}
                    utenteWantsNotifiche={utenteWantsNotifiche}
                    loadingLike={loadingLike}
                    areCommentiLoading={areCommentiLoading}
                    showCommentBtn={true}
                    onLike={likePost}
                    onToggleCommentSection={openSectionCommenti}
                    onChangeNotifiche={changePreferenzaNotifiche}
                    segnalazionePostInviata={segnalazionePostInviata}
                    idQuestionario={props.idQuestionario}
                />
            </Box>
            <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"xl"}
                open={isSectionCommentiOpen}
                onClose={() => setIsSectionCommentiOpen(false)}
                sx={{ position: "absolute", top: 0, left: 0, margin: 0, width: "100%", height: "100%", }}
                PaperProps={{
                    sx: { width: "100%", height: "100%", margin: 0, },
                }}
            >
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box>
                            <IconButton onClick={() => setIsSectionCommentiOpen(false)}><ArrowBack /></IconButton>
                            <Typography variant="h6" component="span">
                                Forum
                            </Typography>
                        </Box>

                        <IconButton onClick={props.closeDialogForum} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Thread
                    objThread={objThread}
                    utenteWantsNotifiche={utenteWantsNotifiche}
                    loadingLike={loadingLike}
                    areCommentiLoading={areCommentiLoading}
                    showCommentBtn={false}
                    onLike={likePost}
                    onToggleCommentSection={openSectionCommenti}
                    onChangeNotifiche={changePreferenzaNotifiche}
                    segnalazionePostInviata={segnalazionePostInviata}
                    idQuestionario={props.idQuestionario}
                />
                    <Typography sx={{ mt: 4, mb: 1 }} variant="h6">Commenti</Typography>
                    <TextField
                        fullWidth
                        sx={{ mb: 2 }}
                        value={textComment}
                        onChange={(e) => setTextCommento(e.target.value)}
                        placeholder="Scrivi il tuo commento"
                        multiline
                        maxRows={4}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled={textComment === "" || commentingLoading} onClick={sendCommento}>
                                        <Send />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Box sx={{ flex: 1, overflow: "hidden", p: 0, position: "relative" }}>
                        <Virtuoso
                            endReached={() => {
                                if (!areCommentiLoading && hasMoreCommenti) {
                                    getCommenti(false);
                                }
                            }}
                            data={objThread.listCommenti}
                            itemContent={(index, commento) => (
                                <Box sx={{ p: 2 }}>
                                    <CommentoPost key={props.postObj.id + "com_" + commento.id} 
                                        commentChanged={(objCommento) => commentoChanged(objCommento, index)}
                                        commentoObj={commento} idQuestionario={props.idQuestionario} idThread={objThread.id}
                                    />
                                </Box>
                            )}
                            style={{ height: "100%", overflowY: "auto" }}
                        />
                        {areCommentiLoading && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                                    textAlign: "center",
                                    py: 1,
                                    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                            >
                                <Typography variant="body2" color="textSecondary">
                                    Caricamento nuovi commenti...
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

interface Props {
    idQuestionario: number,
    threadChanged: (objThread: PostObj | null) => void;
    closeDialogForum: () => void;
    postObj: PostObj;
}
