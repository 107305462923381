import { Close } from "@mui/icons-material";
import {
    Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle,
    IconButton,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { SocialAPI } from "src/api/socialApi";
import { ClassificaObj } from "src/types/questionario.types";

export default function Classifica(props: Props) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isClassificaLoaded, setIsClassificaLoaded] = useState(false);
    const [objClassifica, setObjClassifica] = useState<ClassificaObj | null>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const rowRefs = useRef<HTMLTableRowElement[]>([]); // Riferimenti alle righe
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => { if (props.idQuestionario !== -1) getClassifica(); }, [props.idQuestionario]);

    useEffect(() => {
        if (isDialogOpen && objClassifica) {
            const timeout = setTimeout(() => tableContainerRef.current && scrollToPosition(), 0);
            return () => clearTimeout(timeout);
        }
    }, [isDialogOpen, objClassifica]);

    async function getClassifica() {
        setIsClassificaLoaded(false);
        try {
            const result = await SocialAPI.getClassifica(props.idQuestionario);
            if (result.success) setObjClassifica(result.data);
            else throw new Error("Errore durante il recupero dei dati");
        } catch (error) {
            console.error(error);
        } finally {
            setIsClassificaLoaded(true);
        }
    }

    function scrollToPosition() {
        if (objClassifica && tableContainerRef.current && objClassifica.posto) {
            const container = tableContainerRef.current;
            const targetIndex = objClassifica.posto - 1;
            const targetRow = rowRefs.current[targetIndex];

            if (targetRow) {
                container.scrollTop = targetRow.offsetTop - (container.offsetHeight / 2) + (targetRow.offsetHeight / 2);
            }
        }
    }


    return (
        <Fragment>
            <Button onClick={() => setIsDialogOpen(true)} variant="outlined" sx={{ mt: { xs: 1, md: 0 }, ml: { xs: 0, md: 2 }, width: { xs: "100%", md: "fit-content" } }}>
                Classifica punteggi
            </Button>
            <Dialog fullScreen={isMobile} fullWidth maxWidth="sm" open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="span">Classifica punteggi</Typography>
                        <IconButton onClick={() => setIsDialogOpen(false)} edge="end" aria-label="close">
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    {isClassificaLoaded ? (
                        objClassifica && objClassifica?.classifica.length > 0 ? (
                            <Box sx={{ display: "flex", flexDirection:"column"}}>
                                <Box sx={{flexGrow:"1"}}>
                                    <TableContainer component={Paper} ref={tableContainerRef} sx={{ maxHeight: "60vh", overflowY: "auto", borderRadius: 0, boxShadow: "none" }}>
                                        <Table aria-label="tabella classifica">
                                            <TableHead>
                                                <TableRow sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: theme.palette.background.paper }}>
                                                    {/* <TableCell sx={{ borderBottom: "0px" }}></TableCell> */}
                                                    <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}></TableCell>
                                                    <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}>Punteggi</TableCell>
                                                    <TableCell align="center" sx={{ borderBottom: "2px solid #ccc" }}>Utenti</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {objClassifica.classifica.map((row, index) => (
                                                    <TableRow
                                                        key={row.ordinale}
                                                        ref={(el) => (rowRefs.current[index] = el!)}
                                                        sx={{
                                                            backgroundColor: row.posizione ? "primary.main" : "inherit",
                                                            color: row.posizione ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                                        }}
                                                    >
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                border: "1px solid #ccc",
                                                                color: row.posizione ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            {row.ordinale}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                border: "1px solid #ccc",
                                                                color: row.posizione ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            {row.punteggio}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                border: "1px solid #ccc",
                                                                color: row.posizione ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                                            }}
                                                        >
                                                            {row.utenti}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box sx={{ mt: 2, display: "flex", flexDirection: 'column', alignItems: "center" }}>
                                    <Typography sx={{ mt: 2, textAlign: "center" }}>
                                        {objClassifica?.posto !== null ? (
                                            <span> Sei al <b>{objClassifica?.posto ?? "-"}° posto</b> insieme ad altre {objClassifica.classifica[objClassifica.posto ? objClassifica.posto - 1 : 0].utenti} persone. Continua a esercitarti!</span>
                                        ) : (
                                            <span>Avvia una simulazione per posizionarti in classifica</span>
                                        )}
                                    </Typography>
                                    <Button onClick={props.avviaSimulazione} sx={{ width: { xs: "100%", md: "fit-content" }, mt: 1 }} variant="contained" color="success">
                                        Avvia simulazione
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Fragment>
                                <Typography>La classifica non è disponibile per questo simulatore.</Typography>
                                <Button sx={{ mt: 1 }} fullWidth variant="contained" onClick={() => setIsDialogOpen(false)}>Chiudi finestra</Button>
                            </Fragment>
                        )
                    ) : (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

interface Props {
    avviaSimulazione?: () => void;
    idQuestionario: number;
}
